<template>
  <div class="doctor-ads" v-if="ads.length">
    <ul class="doctor-ads-list">
      <li class="doctor-ads-list__item" v-for="ad of ads" :key="ad.id">
        <a class="doctor-ads-list__item__link" :href="ad.url" target="_blank" rel="noopener noreferrer">{{ ad.title
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DoctorAds',
  computed: {
    ...mapState(['doctorAds', 'appointmentData']),
    ads() {
      const doctorParentId = this.appointmentData?.doctor?.parent_id || '';

      return this.doctorAds.filter((item) => doctorParentId === item.doctor_id && item.status).map((item) => ({
        id: item.uuid,
        title: item.title,
        url: item.url,
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.doctor-ads {}

.doctor-ads-list {

  &__item {

    &+& {
      margin-top: 10px;
    }

    &__link {
      @include font-style($color-theme, 16, 400, 1px);
      @include padding(10px);
      border: 1px solid $color-theme;
      border-radius: 6px;
      text-align: center;
      word-wrap: break-word;
      display: block;
    }
  }
}
</style>
